$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 50) {
      $('.scrolltop:hidden')
        .stop(true, true)
        .fadeIn();
    } else {
      $('.scrolltop')
        .stop(true, true)
        .fadeOut();
    }
  });
  toTop();
});

function toTop() {
  if ($('.wrapper').on().length > 0) {
    $(function() {
      $('html,body').animate(
        {
          scrollTop: $('.wrapper').offset().top
        },
        '1000'
      );
      return false;
    });
  }
}

function saveToTop() {
  if ($('.wrapper').on().length > 0) {
    $(function() {
      $('html,body').animate(
        {
          scrollTop: $('.wrapper').offset().top
        },
        '1000'
      );
      return false;
    });
  }
}
